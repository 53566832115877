import React, { useState, useEffect } from "react";
import { db } from "../../config/firebaseConfig";
import { ref, get } from "firebase/database";
import {
  Award,
  Bell,
  RefreshCcw,
  DollarSign,
  ChevronRight,
  X,
  Plus,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { handleTooltipToggle } from "../../utils/tooltipHelpers";

import { motion, AnimatePresence } from "framer-motion";
import MiniDailyProfitChart from "./MiniDailyProfitChart";
import profileImage from "../../assets/MMTLOGO.png";
import profileImage2 from "../../assets/MMT2LOGO.png";

// 브로커 이미지를 미리 임포트
import monetaLogo from "../../assets/images/exchanges/monetalogo.png";
import notescoLogo from "../../assets/images/exchanges/notescologo.png";
import zeroFinancialLogo from "../../assets/images/exchanges/zerofinanciallimitedlogo.png";
import landPrimeLogo from "../../assets/images/exchanges/landprimeltdlogo.png";
import tickmillLogo from "../../assets/images/exchanges/tickmilllogo.png";
import fxtlogo from "../../assets/images/exchanges/FXT.png";
import cxlogo from "../../assets/images/exchanges/cxlogo.png";
import m4logo from "../../assets/images/exchanges/m4logo.png";
import xmlogo from "../../assets/images/exchanges/xmlogo.png";
import defaultLogo from "../../assets/images/exchanges/defaultlogo.png";

// 브로커 이미지 매핑 및 크기 설정
const brokerImages = {
  "Moneta Markets Limited": {
    img: monetaLogo,
    topSize: "65%",
    smallSize: "24px",
  },
  "NOTESCO Ltd": { img: notescoLogo, topSize: "35%", smallSize: "20px" },
  "Zero Financial Limited": {
    img: zeroFinancialLogo,
    topSize: "65%",
    smallSize: "50px",
  },
  "Land Prime Ltd.": { img: landPrimeLogo, topSize: "65%", smallSize: "26px" },
  "Tickmill Ltd": { img: tickmillLogo, topSize: "90%", smallSize: "50px" },
  "Trinota Markets (Global) Limited": {
    img: m4logo,
    topSize: "60%",
    smallSize: "30px",
  },
  "Capitalxtend (Mauritius) LLC": {
    img: cxlogo,
    topSize: "75%",
    smallSize: "22px",
  },
  "FXTRADING.com Pty Ltd": { img: fxtlogo, topSize: "85%", smallSize: "26px" },
  "XM Global Limited": { img: xmlogo, topSize: "45%", smallSize: "20px" },
  default: { img: defaultLogo, topSize: "80%", smallSize: "24px" },
};

// 브로커 이미지 가져오기 함수
const getBrokerImage = (broker) => {
  return brokerImages[broker] || brokerImages["default"];
};

// 로고 크기 조절을 위한 상수
// 메인 카드 (1~4등)의 로고 크기
const MAIN_CARD_LOGO_SCALE = {
  EA: 0.8,
  Social: 0.8,
};

// 작은 카드 (5등 이하)의 로고 크기
const SMALL_CARD_LOGO_SCALE = {
  EA: 0.7,
  Social: 0.7,
};

const MarketingBoard = () => {
  const [marketingNodes, setMarketingNodes] = useState([]);
  const [nodeDetails, setNodeDetails] = useState({});
  const [selectedTopNode, setSelectedTopNode] = useState(null);
  const [expandedNodes, setExpandedNodes] = useState({});
  const [sortedNodes, setSortedNodes] = useState([]);
  const [filterType, setFilterType] = useState("EA");
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const navigate = useNavigate();

  useEffect(() => {
    fetchMarketingNodes();
  }, []);

  const handleTooltipToggle = (event, nodeId, details) => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setTooltipPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setTooltipData(details);
    setTooltipVisible((prev) => !prev);
  };

  const fetchMarketingNodes = async () => {
    const marketingRef = ref(db, "admin/account/marketing");
    const socialRef = ref(db, "admin/account/social");
    const endRef = ref(db, "admin/account/end");
    const [marketingSnapshot, socialSnapshot, endSnapshot] = await Promise.all([
      get(marketingRef),
      get(socialRef),
      get(endRef),
    ]);

    let allNodes = [];

    if (marketingSnapshot.exists()) {
      const marketingData = marketingSnapshot.val();
      const marketingNodes = Object.keys(marketingData).map((nodeId) => ({
        nodeId,
        type: "EA",
      }));
      allNodes = [...allNodes, ...marketingNodes];
    }

    if (socialSnapshot.exists()) {
      const socialData = socialSnapshot.val();
      const socialNodes = Object.keys(socialData).map((nodeId) => ({
        nodeId,
        type: "Social",
      }));
      allNodes = [...allNodes, ...socialNodes];
    }

    if (endSnapshot.exists()) {
      const endData = endSnapshot.val();
      const endNodes = Object.keys(endData).map((nodeId) => ({
        nodeId,
        type: "End",
      }));
      allNodes = [...allNodes, ...endNodes];
    }

    setMarketingNodes(allNodes);

    const nodeDetailsPromises = allNodes.map((node) =>
      processNodeData(node.nodeId, { name: "Anonymous" }, node.type)
    );
    const fetchedNodeDetails = await Promise.all(nodeDetailsPromises);
    const detailsMap = allNodes.reduce((acc, node, index) => {
      acc[node.nodeId] = fetchedNodeDetails[index];
      return acc;
    }, {});
    setNodeDetails(detailsMap);

    updateSortedNodes(detailsMap, filterType);
  };

  const updateSortedNodes = (details, filter) => {
    const filteredNodes = Object.entries(details)
      // All 탭에서는 End 타입 제외
      .filter(([, node]) => {
        if (filter === "All") {
          return node.type !== "End";
        }
        return filter === "All" || node.type === filter;
      })
      .sort(([, a], [, b]) => b.profitRate - a.profitRate)
      .map(([nodeId]) => nodeId);
    setSortedNodes(filteredNodes);
  };

  const calculateRiskScore = (operationPeriod, profitRate, currentMDD) => {
    let score = 0;
    let details = [];

    // 1. 계좌 구동기간
    if (operationPeriod <= 180) {
      score += 0.5;
      details.push(`구동기간 (${operationPeriod}일): 0.5점`);
    } else if (operationPeriod <= 365) {
      score += 1;
      details.push(`구동기간 (${operationPeriod}일): 1점`);
    } else {
      score += 2;
      details.push(`구동기간 (${operationPeriod}일): 2점`);
    }

    // 2. 수익률
    if (profitRate >= 50) {
      score += 4;
      details.push(`수익률 (${profitRate.toFixed(2)}%): 4점`);
    } else if (profitRate >= 30) {
      score += 3;
      details.push(`수익률 (${profitRate.toFixed(2)}%): 3점`);
    } else if (profitRate >= 10) {
      score += 2;
      details.push(`수익률 (${profitRate.toFixed(2)}%): 2점`);
    } else if (profitRate >= 0) {
      score += 1;
      details.push(`수익률 (${profitRate.toFixed(2)}%): 1점`);
    } else {
      details.push(`수익률 (${profitRate.toFixed(2)}%): 0점`);
    }

    // 3. MDD
    const mdd = parseFloat(currentMDD);
    if (mdd <= 0) {
      score += 4;
      details.push(`MDD (0%): 4점 (만점)`);
    } else if (mdd <= 10) {
      score += 4;
      details.push(`MDD (${mdd.toFixed(2)}%): 4점`);
    } else if (mdd <= 20) {
      score += 2;
      details.push(`MDD (${mdd.toFixed(2)}%): 2점`);
    } else if (mdd < 30) {
      score += 1;
      details.push(`MDD (${mdd.toFixed(2)}%): 1점`);
    } else {
      details.push(`MDD (${mdd.toFixed(2)}%): 0점`);
    }

    return { score, details };
  };

  const processNodeData = async (nodeId, user, type) => {
    const scoreRef = ref(db, `mt4data/${nodeId}/score`);
    const infoRef = ref(db, `mt4data/${nodeId}/info`);
    const [scoreSnapshot, infoSnapshot] = await Promise.all([
      get(scoreRef),
      get(infoRef),
    ]);

    if (scoreSnapshot.exists() && infoSnapshot.exists()) {
      const scoreData = scoreSnapshot.val();
      const infoData = infoSnapshot.val();

      const currentBalance = scoreData.currentBalance || 0;
      const operationPeriod = scoreData.operationPeriod || 0;
      const totalDeposit = scoreData.totalDeposit || 0;
      const totalWithdrawal = Math.abs(scoreData.totalWithdrawal || 0);
      const currentMDD = scoreData.currentMDD || 0;

      const profitAmount = currentBalance + totalWithdrawal - totalDeposit;
      const profitRate =
        totalDeposit > 0 ? (profitAmount / totalDeposit) * 100 : 0;

      // MDD 계산 (퍼센트로)
      const MDD = currentBalance > 0 ? (currentMDD / currentBalance) * 100 : 0;

      const { score: riskScore, details: riskDetails } = calculateRiskScore(
        operationPeriod,
        profitRate,
        MDD
      );

      return {
        nodeId,
        name: user.name,
        profitRate,
        profitAmount,
        currentMDD: MDD.toFixed(2), // MDD를 퍼센트로 계산한 후 소수점 2자리까지 표시
        broker: infoData.Broker || "N/A",
        currentAsset: currentBalance,
        daysOpen: operationPeriod,
        riskScore,
        riskDetails,
        type,
      };
    }
    return null;
  };

  const getStatusColor = (value) => {
    return value >= 0 ? "text-red-500" : "text-sky-300";
  };

  const handleNodeClick = (nodeId) => {
    navigate(`/dashboard/${nodeId}`);
  };

  const renderRiskScoreWithSVG = (score, details, nodeId) => (
    <div className="flex items-center justify-center relative">
      <span>{score.toFixed(1)}</span>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="ml-2 cursor-pointer"
        onClick={(e) => handleTooltipToggle(e, nodeId, details)}
      >
        <g clipPath="url(#clip0_1_276)">
          <path
            d="M12.3211 8.42653C12.2493 9.46863 11.6551 10.2002 10.969 10.5103C10.2416 10.8389 9.50627 10.6577 9.13916 10.059C8.5381 9.07885 8.95111 8.39666 9.3505 7.73691C9.80335 6.98881 10.3167 6.14088 9.82434 4.49194C9.43567 3.19016 8.67449 2.09725 7.56189 1.24356C7.21505 0.977471 6.88611 0.777346 6.61916 0.634033C6.99201 1.5416 7.47686 3.23044 6.9704 5.09631C6.64918 6.27985 5.80023 7.17672 4.97922 8.04406C3.89789 9.1865 2.77968 10.3678 2.77968 12.2553C2.77968 13.7195 3.28983 14.886 4.29591 15.7222C5.05778 16.3555 5.8352 16.6021 5.84295 16.604H7.69803L7.6592 16.6033C7.65205 16.5977 6.94531 16.0398 6.93314 15.4001C6.92747 15.101 7.08439 14.8142 7.39956 14.5475C7.87295 14.1469 8.13314 13.7156 8.19503 13.2289C8.23289 12.9313 8.19462 12.6167 8.0783 12.277C8.86348 12.6063 9.67567 13.3253 9.9509 14.2178C10.1981 15.0194 9.96943 15.8437 9.2903 16.604H11.3205C12.6504 15.5622 13.399 14.3136 13.5458 12.8931C13.7329 11.0819 12.9048 9.37603 12.3211 8.42653Z"
            fill="#0C0B0B"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_276">
            <rect
              width="15.2"
              height="16"
              fill="white"
              transform="translate(0.576172 0.634033)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );

  const TopNodeCard = ({ node, rank }) => {
    const details = nodeDetails[node] || {};
    const profitRateColor = getStatusColor(details.profitRate);
    const profitAmountColor = getStatusColor(details.profitAmount);
    const { img: brokerImage, topSize: brokerImageSize } = getBrokerImage(
      details.broker
    );
    const nodeProfileImage =
      details.type === "EA" ? profileImage : profileImage2;
    return (
      <motion.div
        layout
        className="relative bg-white rounded-xl shadow-lg overflow-hidden flex flex-col items-center w-full max-w-[370px] h-auto sm:h-[470px]"
      >
        <div
          className="absolute top-0 left-0 w-full bg-no-repeat"
          style={{
            backgroundImage: `url(${brokerImage})`,
            height: "20%",
            borderBottom: "2px solid black",
            backgroundSize: brokerImageSize,
            backgroundPosition: "center",
          }}
        ></div>

        <div className="absolute top-2 right-2 bg-white rounded-full w-6 h-6 sm:w-8 sm:h-8 flex items-center justify-center shadow-md">
          <span className="text-gray-600 text-xs sm:text-sm">
            {rank === 1
              ? "1st"
              : rank === 2
              ? "2nd"
              : rank === 3
              ? "3rd"
              : "4th"}
          </span>
        </div>

        <div className="relative w-20 h-20 sm:w-24 sm:h-24 mt-12 sm:mt-16 aspect-square">
          <div className="absolute inset-0 bg-white rounded-full p-1 border-2 border-black">
            <div className="w-full h-full bg-white rounded-full overflow-hidden flex items-center justify-center">
              <img
                src={nodeProfileImage}
                alt="profile"
                className="w-4/5 h-4/5 object-contain"
              />
            </div>
          </div>
        </div>

        <h3 className="text-sm sm:text-lg text-gray-800 mt-2">{node}</h3>
        <div className="flex items-center">
          <span
            className={`text-xs sm:text-sm ${
              details.type === "EA" ? "text-green-500" : "text-blue-500"
            }`}
          >
            {details.type}
          </span>
        </div>

        <div className="grid grid-cols-2 gap-1 sm:gap-2 text-center mt-2 sm:mt-4 w-full px-2 sm:px-4">
          <div>
            <span className={`block text-sm sm:text-xl ${profitRateColor}`}>
              {details.profitRate?.toFixed(2)}%
            </span>
            <span className="block text-gray-500 text-xs sm:text-sm">
              수익률
            </span>
          </div>
          <div>
            <span className="block text-gray-700 text-sm sm:text-lg">
              {details.daysOpen || 0}
            </span>
            <span className="block text-gray-500 text-xs sm:text-sm">
              구동기간
            </span>
          </div>
          <div>
            {renderRiskScoreWithSVG(
              details.riskScore,
              details.riskDetails,
              node
            )}
            <span className="block text-gray-500 text-xs sm:text-sm">
              Score
            </span>
          </div>
          <div>
            <span className={`block text-sm sm:text-xl ${profitAmountColor}`}>
              ${details.profitAmount?.toFixed(2)}
            </span>
            <span className="block text-gray-500 text-xs sm:text-sm">
              수익금
            </span>
          </div>
          <div className="col-span-2">
            <span className="block text-sm sm:text-lg text-red-500">
              {details.currentMDD}%
            </span>
            <span className="block text-gray-500 text-xs sm:text-sm">MDD</span>
          </div>
        </div>

        <div className="flex justify-around mt-2 sm:mt-4 mb-2 sm:mb-4 w-full">
          <button
            className="bg-black text-white rounded-full shadow-lg hover:bg-gray-800 transition duration-300 border border-white text-xs sm:text-sm px-2 sm:px-4 py-1 sm:py-2"
            onClick={() => setSelectedTopNode(node)}
          >
            Summary
          </button>
          <button
            className="bg-black text-white rounded-full shadow-lg hover:bg-gray-800 transition duration-300 border border-white text-xs sm:text-sm px-2 sm:px-4 py-1 sm:py-2"
            onClick={() => handleNodeClick(node)}
          >
            Detail
          </button>
        </div>
      </motion.div>
    );
  };

  const DetailedNodeInfo = ({
    node,
    onClose,
    chartWidth = "100%",
    chartHeight = "200px",
  }) => {
    const details = nodeDetails[node] || {};
    const profitRateColor = getStatusColor(details.profitRate);
    const profitAmountColor = getStatusColor(details.profitAmount);
    const nodeProfileImage =
      details.type === "EA" ? profileImage : profileImage2;
    const logoScale = MAIN_CARD_LOGO_SCALE[details.type];

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3 }}
        className="bg-white p-6 rounded-xl shadow-lg mt-4"
        style={{ width: chartWidth }}
      >
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center">
            <div className="w-10 h-10 rounded-full border-2 border-black overflow-hidden mr-4">
              <img
                src={nodeProfileImage}
                alt="profile"
                className="w-full h-full object-contain"
                style={{
                  objectFit: "contain",
                  objectPosition: "center",
                  transform: `scale(${logoScale})`,
                }}
              />
            </div>
            <h3 className="text-xl">{node}</h3>
          </div>
          <button onClick={onClose}>
            <X size={24} />
          </button>
        </div>
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <h4 className="text-lg">Broker</h4>
            <p className="text-base">{details.broker}</p>
          </div>
          <div>
            <h4 className="text-lg">Current Asset</h4>
            <p className="text-base">${details.currentAsset?.toFixed(2)}</p>
          </div>
          <div>
            <h4 className="text-lg">Profit Rate</h4>
            <p className={`text-base ${profitRateColor}`}>
              {details.profitRate?.toFixed(2)}%
            </p>
          </div>
          <div>
            <h4 className="text-lg">Profit Amount</h4>
            <p className={`text-base ${profitAmountColor}`}>
              ${details.profitAmount?.toFixed(2)}
            </p>
          </div>
          <div>
            <h4 className="text-lg">Days Open</h4>
            <p className="text-base">{details.daysOpen}</p>
          </div>
          <div>
            <h4 className="text-lg">Type</h4>
            <p className="text-base">{details.type}</p>
          </div>
        </div>
        <div className="mt-4">
          <h4 className="text-lg mb-2">Daily Profit Chart</h4>
          <MiniDailyProfitChart
            nodeId={node}
            width={chartWidth}
            height={chartHeight}
          />
        </div>
      </motion.div>
    );
  };

  const SmallNodeItem = ({ node, index }) => {
    const details = nodeDetails[node] || {};
    const profitRateColor = getStatusColor(details.profitRate);
    const profitAmountColor = getStatusColor(details.profitAmount);
    const nodeProfileImage =
      details.type === "EA" ? profileImage : profileImage2;
    const logoScale = SMALL_CARD_LOGO_SCALE[details.type];
    const { img: brokerImage, smallSize: brokerImageSize } = getBrokerImage(
      details.broker
    );
    const isExpanded = expandedNodes[node];

    const handleToggleExpand = () => {
      setExpandedNodes((prev) => ({ ...prev, [node]: !prev[node] }));
    };

    const handleSummaryClick = () => {
      setSelectedTopNode(node);
    };

    const handleDetailClick = () => {
      handleNodeClick(node);
    };

    const renderButtons = () => (
      <>
        <button
          className="bg-black text-white rounded-full shadow-md hover:bg-gray-800 transition duration-300 text-xs px-3 py-1 mr-2"
          onClick={handleSummaryClick}
        >
          Summary
        </button>
        <button
          className="bg-black text-white rounded-full shadow-md hover:bg-gray-800 transition duration-300 text-xs px-3 py-1"
          onClick={handleDetailClick}
        >
          Detail
        </button>
      </>
    );

    return (
      <motion.div
        layout
        className="relative bg-white rounded-lg shadow-md p-2 sm:p-4 mb-2 sm:mb-4"
      >
        <div
          className="hidden sm:grid"
          style={{ gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr" }}
        >
          <div className="flex items-center">
            <div className="w-8 h-8 rounded-full border-2 border-black overflow-hidden mr-2">
              <img
                src={nodeProfileImage}
                alt="profile"
                className="w-full h-full object-contain"
                style={{
                  objectFit: "contain",
                  objectPosition: "center",
                  transform: `scale(${logoScale})`,
                }}
              />
            </div>
            <div className="font-medium text-sm truncate">{node}</div>
          </div>
          <div className="flex items-center justify-center">
            <img
              src={brokerImage}
              alt={details.broker}
              style={{
                height: brokerImageSize,
                width: "auto",
                objectFit: "contain",
              }}
            />
          </div>
          <div
            className={`text-sm text-center ${
              details.type === "EA" ? "text-green-500" : "text-blue-500"
            }`}
          >
            {details.type}
          </div>
          <div className="text-sm text-center">{details.daysOpen || 0}</div>
          <div className={`text-sm text-center ${profitRateColor}`}>
            {details.profitRate?.toFixed(2)}%
          </div>
          <div className={`text-sm text-center ${profitAmountColor}`}>
            ${details.profitAmount?.toFixed(2)}
          </div>
          <div className="text-sm text-center">
            {renderRiskScoreWithSVG(
              details.riskScore,
              details.riskDetails,
              node
            )}
          </div>
          <div className="text-sm text-center text-red-500">
            {details.currentMDD}%
          </div>
          <div className="text-center">
            {isExpanded ? (
              renderButtons()
            ) : (
              <button
                className="bg-black text-white rounded-lg shadow-md hover:bg-gray-800 transition duration-300 text-xs px-2 py-1"
                onClick={handleToggleExpand}
              >
                More
              </button>
            )}
          </div>
        </div>

        {/* Mobile layout */}
        <div className="sm:hidden">
          <div className="flex justify-between items-center mb-2">
            <div className="flex items-center">
              <div className="w-8 h-8 rounded-full border-2 border-black overflow-hidden mr-2">
                <img
                  src={nodeProfileImage}
                  alt="profile"
                  className="w-full h-full object-contain"
                  style={{
                    objectFit: "contain",
                    objectPosition: "center",
                    transform: `scale(${logoScale})`,
                  }}
                />
              </div>
              <div className="font-medium text-sm">{node}</div>
            </div>
            <img
              src={brokerImage}
              alt={details.broker}
              style={{ height: "24px", width: "auto", objectFit: "contain" }}
            />
          </div>

          <div className="grid grid-cols-3 gap-1 text-xs mb-2">
            <div>
              <span className="text-gray-500">Type:</span>{" "}
              <span
                className={
                  details.type === "EA" ? "text-green-500" : "text-blue-500"
                }
              >
                {details.type}
              </span>
            </div>
            <div>
              <span className="text-gray-500">Days:</span>{" "}
              {details.daysOpen || 0}
            </div>
            <div>
              <span className="text-gray-500">Profit:</span>{" "}
              <span className={profitRateColor}>
                {details.profitRate?.toFixed(2)}%
              </span>
            </div>
            <div>
              <span className="text-gray-500">Amount:</span>{" "}
              <span className={profitAmountColor}>
                ${details.profitAmount?.toFixed(2)}
              </span>
            </div>
            <div>
              <span className="text-gray-500">MDD:</span>{" "}
              <span className="text-red-500">{details.currentMDD}%</span>
            </div>
          </div>

          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <span className="text-gray-500 text-xs mr-1">Score:</span>
              {renderRiskScoreWithSVG(
                details.riskScore,
                details.riskDetails,
                node
              )}
            </div>
            {isExpanded ? (
              <div>{renderButtons()}</div>
            ) : (
              <button
                className="bg-black text-white rounded-full shadow-md hover:bg-gray-800 transition duration-300 text-xs px-3 py-1"
                onClick={handleToggleExpand}
              >
                More
              </button>
            )}
          </div>
        </div>
      </motion.div>
    );
  };
  const TableHeader = () => {
    return (
      <div
        className="hidden sm:grid gap-4 text-center w-full px-4 py-2 bg-gray-200 font-bold"
        style={{
          gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
        }}
      >
        <div className="text-left">Node</div>
        <div>Broker</div>
        <div>Type</div>
        <div>Days</div>
        <div>Profit Rate</div>
        <div>Profit Amount</div>
        <div>Score</div>
        <div>MDD</div>
        <div>Actions</div>
      </div>
    );
  };

  return (
    <div className="bg-white border border-gray-200 rounded-lg shadow-md p-2 sm:p-4 mb-4 overflow-x-auto">
      <div className="min-w-[350px]">
        <div className="flex flex-col sm:flex-row justify-between items-center mb-4 sm:mb-6">
          <h2 className="text-xl sm:text-2xl mb-2 sm:mb-0">Marketing Board</h2>
          <div className="flex space-x-1 sm:space-x-2">
            {["EA", "Social", "End", "All"].map((type) => (
              <button
                key={type}
                className={`px-2 sm:px-4 py-1 sm:py-2 rounded-full text-xs sm:text-sm ${
                  filterType === type
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
                onClick={() => {
                  setFilterType(type);
                  updateSortedNodes(nodeDetails, type);
                }}
              >
                {type}
              </button>
            ))}
          </div>
        </div>
        <motion.div
          layout
          className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-2 sm:gap-4 md:gap-6 mb-6 sm:mb-12"
        >
          <AnimatePresence>
            {sortedNodes.slice(0, 4).map((node, index) => (
              <TopNodeCard key={node} node={node} rank={index + 1} />
            ))}
          </AnimatePresence>
        </motion.div>

        <motion.div layout>
          <AnimatePresence>
            {selectedTopNode && (
              <motion.div
                key="detailedInfoTop"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className="mb-4"
              >
                <DetailedNodeInfo
                  node={selectedTopNode}
                  onClose={() => setSelectedTopNode(null)}
                  chartWidth="100%"
                  chartHeight="200px"
                />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        <motion.div layout className="grid grid-cols-1 gap-4">
          <AnimatePresence>
            {sortedNodes.length > 4 && (
              <>
                <TableHeader />
                <motion.div layout className="grid grid-cols-1 gap-4">
                  <AnimatePresence>
                    {sortedNodes.slice(4).map((node, index) => (
                      <React.Fragment key={node}>
                        <SmallNodeItem node={node} index={index} />
                        {expandedNodes[node] && (
                          <motion.div
                            key={`detailed-${node}`}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.3 }}
                          >
                            <DetailedNodeInfo
                              node={node}
                              onClose={() =>
                                setExpandedNodes((prev) => ({
                                  ...prev,
                                  [node]: false,
                                }))
                              }
                            />
                          </motion.div>
                        )}
                      </React.Fragment>
                    ))}
                  </AnimatePresence>
                </motion.div>
              </>
            )}
          </AnimatePresence>
        </motion.div>
      </div>
      {tooltipVisible && (
        <div
          className="fixed z-50 p-3 bg-white text-black rounded-lg shadow-lg border-2 border-black"
          style={{
            top: `${tooltipPosition.top}px`,
            left: `${tooltipPosition.left}px`,
            minWidth: "200px",
            animation: "fadeIn 0.3s",
          }}
        >
          <button
            className="absolute top-1 right-1 text-black hover:text-gray-700"
            onClick={() => setTooltipVisible(false)}
          >
            X
          </button>
          {tooltipData &&
            tooltipData.map((detail, index) => (
              <p key={index} className="text-sm mb-1">
                {detail}
              </p>
            ))}
        </div>
      )}
    </div>
  );
};

export default MarketingBoard;
